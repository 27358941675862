import React from 'react'
import { Divider, Flex, Text } from 'ui-lib'
import moment from 'utils/moment'

import { getPaymentDueText } from '@/src/components/capital/utils/repaymentFrequencyParsers'
import { TextRow } from '@/src/components/misc'
import { PlainStatementExtensions, RepaymentFrequency } from '@/types/capital'

type StatementCardDetailsProps = {
  funding: PlainStatementExtensions[number]
  totalAmount: string
}

export const StatementCardDetails = ({ funding, totalAmount }: StatementCardDetailsProps) => {
  return (
    <Flex flex={1} direction="column" gap="24px" padding="24px">
      <Text textStyle="title-sm" textColor="text-primary">
        Financing Details
      </Text>
      <Flex direction="column" gap="12px">
        <TextRow>
          <TextRow.Title>Financing amount</TextRow.Title>
          <TextRow.Value>{funding.principalAmount}</TextRow.Value>
        </TextRow>
        <TextRow>
          <TextRow.Title>Total fee</TextRow.Title>
        </TextRow>
        <Divider />
        <TextRow>
          <TextRow.Title>Total due</TextRow.Title>
          <TextRow.Value textStyle="title-md">
            <Flex>{totalAmount}</Flex>
            <Text textStyle="paragraph-sm" textColor="text-secondary" align="end">
              {getPaymentDueText(
                moment(funding.firstRepaymentDate).utc(),
                funding.repaymentFrequency as RepaymentFrequency
              )}
            </Text>
          </TextRow.Value>
        </TextRow>
      </Flex>
    </Flex>
  )
}
