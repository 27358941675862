import { Text } from 'ui-lib'
import { isDefined, Money } from 'utils'

import { date } from '@/src/utils/date'
import { FundingStatus, GenericFunding } from '@/types/capital'

import { StatusBar } from '.'

interface FundingCardActionProps {
  lastFundingPayment: GenericFunding['lastFundingPayment']
  status: FundingStatus
  nextPaymentOn: string | null
  open: boolean
}

export const FundingCardAction = ({ lastFundingPayment, status, nextPaymentOn, open }: FundingCardActionProps) => {
  if (status !== FundingStatus.ActiveFundingStatus.DEPOSITED) {
    return null
  }

  const lastPaymentStatus = lastFundingPayment?.status
  const hasPaymentStatus = isDefined(lastFundingPayment) && isDefined(lastPaymentStatus)
  const isWithinTwoDays =
    isDefined(lastFundingPayment?.date) && date().isBefore(date(lastFundingPayment?.date).add(2, 'day'))

  switch (true) {
    case hasPaymentStatus && lastPaymentStatus === 'FAILED' && isWithinTwoDays:
      const failedAmount = Money.fromNumber(lastFundingPayment?.amount ?? 0).toFormattedCurrencyString()
      return (
        <StatusBar open={open} variant="error">
          <StatusBar.Content>
            Your recent payment of {failedAmount} Failed. You can retry the payment using retry button.
          </StatusBar.Content>
          <StatusBar.Button>Retry</StatusBar.Button>
        </StatusBar>
      )

    case hasPaymentStatus && lastPaymentStatus === 'IN_PROGRESS':
      const inProgressAmount = Money.fromNumber(lastFundingPayment?.amount ?? 0).toFormattedCurrencyString()
      return (
        <StatusBar open={open} variant="info">
          <StatusBar.Content>
            Your payment of {inProgressAmount} is processing. It will take 2-3 business days to settle.
          </StatusBar.Content>
          <StatusBar.Button>Cancel payment</StatusBar.Button>
        </StatusBar>
      )

    case isDefined(nextPaymentOn):
      return (
        <StatusBar open={open} variant="disabled">
          <StatusBar.Content>
            <Text textStyle="paragraph-md" textColor="text-soft">
              Next payment on {nextPaymentOn}
            </Text>
          </StatusBar.Content>
          <StatusBar.Button>Pay in full</StatusBar.Button>
        </StatusBar>
      )

    default:
      return null
  }
}
