import Link from 'next/link'
import { useRef } from 'react'
import { Button, Flex, isDefined, Text } from 'ui-lib'
import { Money } from 'utils'

import { CreditLimitStatusTag, UnderwritingBanner } from '@/src/components/capital'
import InfoCard from '@/src/components/molecules/InfoCard'
import { SEGMENT_PAGE_VISITED } from '@/src/constants/segmentVisitedPages'
import { useEffectOnce, useInternalUser } from '@/src/hooks/misc'
import { useSegmentPageVisited } from '@/src/hooks/useSegmentPageVisited'
import AvailableCredit from '@/src/svgs/capital/AvailableCredit'
import GrowthTable from '@/src/svgs/capital/GrowthTable'
import LoanTable from '@/src/svgs/capital/LoanTable'
import PaymentSchedule from '@/src/svgs/capital/PaymentSchedule'
import { isUnderwritingPeriodExpired } from '@/src/utils/capital'
import { useInternalUserStatus } from '@/src/utils/user-session'

import CapitalApprovedCard from './CapitalApprovedCard'

type CapitalApprovedSectionProps = {
  amountApproved?: number
}

const CapitalApprovedSection = ({ amountApproved }: CapitalApprovedSectionProps) => {
  const measureTimer = useRef(Date.now())
  const { userBusinessIsActive } = useInternalUserStatus()
  const showUnderwrittenBanner = userBusinessIsActive && isDefined(amountApproved)
  const user = useInternalUser()
  const underwrittenDate = user?.business?.underwrittenAt
  const { isCapitalBlocked } = useInternalUserStatus()

  const trackPageVisited = useSegmentPageVisited()

  useEffectOnce(() => {
    const loadTime = Date.now() - measureTimer.current
    trackPageVisited({
      type: SEGMENT_PAGE_VISITED.FINANCING_LANDING_VIEWED,
      payload: {
        team: 'Capital',
        page: 'Capital Landing Screen',
        loadTime,
        capital_limit_expired: isUnderwritingPeriodExpired(underwrittenDate),
      },
    })
  })

  return (
    <>
      {showUnderwrittenBanner && (
        <>
          <UnderwritingBanner />
          <Flex flexDir="column" gap={3} align="flex-start" mt={6} w="full">
            <Text textStyle="title-md" color="text-secondary">
              Total Credit Limit
            </Text>
            <Flex gap="3" alignItems="center">
              <Text textStyle="headline-3">{Money.fromNumber(amountApproved).toFormattedCurrencyString()}</Text>
              <CreditLimitStatusTag />
            </Flex>
          </Flex>
        </>
      )}
      <Flex px="96px" py="48px" flexDirection="column" alignItems="center" justifyContent="center">
        <Flex maxWidth="702px" flexDirection="column" alignItems="center" justifyContent="center">
          {!showUnderwrittenBanner && <CapitalApprovedCard amountApproved={amountApproved} />}

          <Text textStyle="heading.md.semibold" mb="72px" mt={showUnderwrittenBanner ? 0 : '72px'}>
            How it works
          </Text>
          <Flex width="100%" gap="48px" mb="72px" alignItems="center">
            <InfoCard
              width="100%"
              title="Apply"
              description={`Select how much funding you need. Pick a repayment plan that works best for you. \n \n  Get your money within 1 business day!`}
            />
            <LoanTable amountApproved={amountApproved} width={'100%'} />
          </Flex>
          <Flex width="100%" gap="48px" mb="72px" alignItems="center">
            <PaymentSchedule width={'100%'} />
            <InfoCard
              width="100%"
              title="No hidden fee"
              description={`We charge only one fee - no personal guarantees or surprises. \n \n And you can even lower your fees by up to 1/3rd with your Keep rewards.`}
            />
          </Flex>
          <Flex width="100%" gap="48px" mb="72px" alignItems="center">
            <InfoCard
              width="100%"
              title="Get more capital"
              description={`Withdraw your remaining pre-approved funds at any time. \n \n The pre-approved limit resets with each completed repayment!`}
            />
            <AvailableCredit width={'100%'} ml="-2%" />
          </Flex>
          <Flex width="100%" gap="48px" mb="72px" alignItems="center">
            <GrowthTable width={'100%'} />
            <InfoCard
              width="100%"
              title="Funding that grows"
              description={`Grow your business with Keep Capital. \n \n When your business grows, your pre-approved amount grows too!`}
            />
          </Flex>
          <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Text textStyle="heading.xs.medium" mb="24px">
              Apply and get capital now:
            </Text>
            <Button isDisabled={isCapitalBlocked} size="xl" borderRadius="16px" padding="20px 24px 20px 24px">
              <Link
                href="/capital/apply"
                onClick={() => {
                  trackPageVisited({
                    type: SEGMENT_PAGE_VISITED.FINANCING_APP_STARTED,
                    payload: {
                      team: 'Capital',
                      page: 'Capital Landing Screen',
                      request_type: 'new',
                      version: 'v1',
                      capital_limit_expired: isUnderwritingPeriodExpired(underwrittenDate),
                    },
                  })
                }}
              >
                <Text textStyle="heading.xs.medium" color="white">
                  Get started
                </Text>
              </Link>
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default CapitalApprovedSection
