import React from 'react'
import { Flex, Icon, Text } from 'ui-lib'
import { Money } from 'utils'

interface AmountStepProps {
  outstandingAmount: Money
  proRatedFees: Money
  fees: Money
}

export const AmountStep = ({ outstandingAmount, fees, proRatedFees }: AmountStepProps) => {
  const totalAmount = outstandingAmount.add(proRatedFees).toFormattedCurrencyString()
  return (
    <>
      <Flex gap="2" mb="4" flexDir="column">
        <Text textStyle="subheading-md" color="text-soft" textTransform="uppercase">
          Total amount to be paid back
        </Text>
        <Text textStyle="headline-3">{totalAmount}</Text>
      </Flex>
      <Flex gap="2" flexDir="column">
        <Text textStyle="subheading-md" color="text-soft" textTransform="uppercase" mb="1">
          How is it applied
        </Text>
        <Flex borderRadius="sm" bgColor="bg-disabled" p="4" gap="1" flexDir="column">
          <Flex justifyContent="space-between">
            <Text textStyle="paragraph-md" color="text-secondary">
              Outstanding principal
            </Text>
            <Text textStyle="paragraph-md">{outstandingAmount.toFormattedCurrencyString()}</Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text textStyle="paragraph-md" color="text-secondary">
              Pro-rated fee
            </Text>
            <Text textStyle="paragraph-md">{proRatedFees.toFormattedCurrencyString()}</Text>
          </Flex>
        </Flex>
        <Flex borderRadius="sm" bgColor="bg-disabled" p="4" justifyContent="space-between">
          <Flex gap="1">
            <Flex borderRadius="full" p="1" bg="text-disabled" h="fit-content">
              <Icon icon="percentage" variant="inverse" size="xs" />
            </Flex>
            <Flex flexDir="column">
              <Text textStyle="title-xs">Remaining Outstanding Principal</Text>
              <Text color="text-secondary" textStyle="paragraph-sm">
                {`You’re saving ${fees.sub(proRatedFees).toFormattedCurrencyString()} in fee`}
              </Text>
            </Flex>
          </Flex>
          <Text textStyle="title-xs">$0.00</Text>
        </Flex>
      </Flex>
    </>
  )
}
