import React from 'react'
import { Divider, Flex, Icon, Text, Tooltip } from 'ui-lib'
import { isDefined, Money } from 'utils'
import moment from 'utils/moment'

import { FundingPaginated } from '@/gql'
import { TextRow } from '@/src/components/misc'
import { RepaymentFrequency } from '@/types/capital'
import { FundingDiscountType as FundingDiscountTypeEnum } from '@/types/capital/enums'

import { getPaymentDueText } from '../utils/repaymentFrequencyParsers'

type FundingCardDetailsProps = {
  funding: FundingPaginated
  totalAmount: string
}

export const FundingCardDetails = ({ funding, totalAmount }: FundingCardDetailsProps) => {
  const isDiscountApplied = isDefined(funding.feeDiscountRate) && funding.feeDiscountRate > 0

  const sumOfRewardsDiscounts = funding.discounts?.reduce((acc, crr) => {
    if (crr.type === FundingDiscountTypeEnum.REWARDS_DISCOUNT) {
      const discount = Money.fromFormattedCurrencyString(crr.amount)
      return acc.add(discount)
    }
    return acc
  }, Money.fromFormattedCurrencyString('0'))

  const sumOfFlatDiscounts = funding.discounts?.reduce((acc, crr) => {
    if (crr.type === FundingDiscountTypeEnum.FLAT_DISCOUNT) {
      const discount = Money.fromFormattedCurrencyString(crr.amount)
      return acc.add(discount)
    }
    return acc
  }, Money.fromFormattedCurrencyString('0'))

  const feeLessFlatDiscount = Money.fromNumber(funding.preDiscountTotalFee.amountAsNumber)
    .sub(sumOfFlatDiscounts)
    .toFormattedCurrencyString()

  return (
    <Flex flex={1} direction="column" gap="24px" padding="24px">
      <Text textStyle="title-sm" textColor="text-primary">
        Financing Details
      </Text>
      <Flex direction="column" gap="12px">
        <TextRow>
          <TextRow.Title>Financing amount</TextRow.Title>
          <TextRow.Value>{funding.principalAmount.amount}</TextRow.Value>
        </TextRow>
        <TextRow>
          <TextRow.Title>Total fee</TextRow.Title>
          <TextRow.Value>
            {sumOfFlatDiscounts.greaterThan(Money.fromNumber(0)) ? (
              <>
                <Text as="del" color="text-soft">
                  {funding.preDiscountTotalFee.amount}
                </Text>{' '}
                <Text as="span" color="text-success">
                  {feeLessFlatDiscount}
                </Text>
              </>
            ) : (
              funding.preDiscountTotalFee.amount
            )}
          </TextRow.Value>
        </TextRow>
        {sumOfRewardsDiscounts.greaterThan(Money.fromNumber(0)) && (
          <TextRow>
            <TextRow.Title display="flex" alignItems="center" gap={1}>
              Rewards redemption discount
              <Tooltip label="Rewards discount is applied on the fee amount.">
                <Icon size="sm" icon="info-fill" />
              </Tooltip>
            </TextRow.Title>

            <TextRow.Value
              css={{
                background: 'linear-gradient(236deg, #DB57B6 0%, #6497C7 100%)',
                backgroundClip: 'text',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
              }}
            >
              - {sumOfRewardsDiscounts.toFormattedCurrencyString()}
            </TextRow.Value>
          </TextRow>
        )}
        <Divider />
        <TextRow>
          <TextRow.Title>Total due</TextRow.Title>
          <TextRow.Value textStyle="title-md">
            <Flex>
              <Icon display={isDiscountApplied ? 'block' : 'none'} icon="tag-fill" variant="success" />
              {totalAmount}
            </Flex>
            <Text textStyle="paragraph-sm" textColor="text-secondary" align="end">
              {getPaymentDueText(
                moment(funding.firstRepaymentDate).utc(),
                funding.repaymentFrequency as RepaymentFrequency
              )}
            </Text>
          </TextRow.Value>
        </TextRow>
      </Flex>
    </Flex>
  )
}
