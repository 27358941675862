import { Money } from 'utils'

import { CapitalFunding, FundingStatus, GenericFunding, PlainStatementExtensions } from '@/types/capital'

export const transformPaginatedFundingToGeneric = (funding: CapitalFunding): GenericFunding => {
  return {
    fundingId: funding.id,
    loanNumber: funding.loanNumber,
    status: funding.status as FundingStatus,
    totalRepayment: funding.totalRepayment.amountAsNumber,
    principalAmount: funding.principalAmount.amountAsNumber,
    nextPaymentDate: funding.nextPaymentDate,
    lastFundingPayment: {
      amount: funding.lastFundingPayment?.amount?.amountAsNumber ?? 0,
      status: funding.lastFundingPayment?.status,
      date: funding.lastFundingPayment?.createdAt,
    },
  }
}

export const transformPlainStatementExtensionToGeneric = (
  funding: PlainStatementExtensions[number]
): GenericFunding => {
  return {
    fundingId: funding.id,
    loanNumber: funding.loanNumber,
    status: funding.status as FundingStatus,
    totalRepayment: Money.fromFormattedCurrencyString(funding.totalRepayment).toNumber(),
    principalAmount: Money.fromFormattedCurrencyString(funding.principalAmount).toNumber(),
    nextPaymentDate: funding.nextPaymentDate,
    lastFundingPayment: {
      amount: funding.lastFundingPayment?.amount?.amountAsNumber ?? 0,
      status: funding.lastFundingPayment?.status,
      date: funding.lastFundingPayment?.createdAt,
    },
  }
}
