import { FundingPaginated } from '@/gql'

export const mapLoanToTrackedLoan = (loan: FundingPaginated) => {
  const totalAmount = loan.totalFee.amountAsNumber + loan.principalAmount.amountAsNumber

  return {
    loanNumber: loan.loanNumber,
    financingAmount: loan.principalAmount.amountAsNumber,
    totalFee: loan.totalFee?.amountAsNumber,
    totalPaymentAmount: totalAmount,
    status: loan.status,
    paidToDate: loan.totalRepayment?.amountAsNumber,
    amountRemaining: loan.outstandingBalance?.amountAsNumber,
    nextPaymentDate: loan.nextPaymentDate,
  }
}
