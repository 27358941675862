import { ReactNode } from 'react'
import { Divider, Flex, Text } from 'ui-lib'

import { useQueryParamState } from '@/src/hooks/misc'

import { SimplePagination } from '../misc'

export const FundingsList = ({
  children,
  pages,
  showPagination = true,
}: {
  children: ReactNode
  pages?: number
  showPagination?: boolean
}) => {
  const [page, setPage] = useQueryParamState<number>('page')

  return (
    <>
      <Flex direction="row" width="100%" display={['none', 'flex']}>
        <Flex width="50%" direction="row">
          <Text textStyle="subheading-sm" textColor="text-soft">
            PLAN ID
          </Text>
        </Flex>
        <Flex width="50%" direction="row">
          <Text textStyle="subheading-sm" textColor="text-soft">
            PAID TO DATE
          </Text>
        </Flex>
      </Flex>
      <Divider display={['none', 'flex']} />
      {children}
      {showPagination && (
        <SimplePagination currentPage={page as number} totalPages={pages ?? 1} onPageChange={setPage} />
      )}
    </>
  )
}
